import {popupInfoDialog} from "./info-dialog";

export function setCookie(name, value, exDays) {
    let d = new Date();
    if(!localStorage.getItem('_tv_cookie_choice')) {
        d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
    } else {
        d.setTime(d.getTime() + (exDays * -1000));
    }
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
export function setCookieForADay(name, value) {
    let d = new Date();
    if(!localStorage.getItem('_tv_cookie_choice')) {
        d.setHours(23, 59, 59, 999);
    } else {
        d.setHours(-23, 59, 59, 999);
    }
    let expires = "expires="+ d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
export function getCookie(name) {
    name = name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return "";
}
export function delCookie(name){ /* Delete only one cookie by name */
    var d = window.location.hostname.split(".");
    /* Full domain name */
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    /* Domain with only name and .com */
    document.cookie = name+"=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain="+d[1]+'.'+d[2]+";";
}
export function delAllCookies(){
    /* Delete all cokies */
    var cookies = document.cookie.split("; ");
    for (var c = 0; c < cookies.length; c++) {
        delCookie(cookies[c].split(";")[0].split("=")[0]);
    }
}
export function blockedAllCookies(){
    $.post(
        '/ajax/information/cookies-message',
        {
            type: 'none'
        },
        function (data) {
            if (data.message && $("#cookie-configuration").length === 0) {
                popupInfoDialog(data.message);
            }
        },
        'json'
    );
}