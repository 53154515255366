import {getCookie, setCookie} from "../../library/cookies";
import {trackEvent} from "../../library/analytics";
import {ga4TrackSimpleEvent} from "../../website/webpack/modules/analytics";
import {getModal} from "../../website/webpack/modules/modal";

let cookieName ="newsletter_loyalty_cookie";
var popupEventSend = false;

$(document).ready(function() {
    $('.footer-link .open-modal-newsletter').click(function () {
        openModal();
        trackEvent('Newsletter', 'Footer', 'Clic');
        if (!popupEventSend) {
            dataLayer.push({
                event: 'newsletter_show',
                method: 'footer'
            });
            popupEventSend = true;
        }
    });

    $('.loyaltyNewsletter .open-modal-newsletter').click(function () {
        openModal();
        trackEvent('Newsletter', 'Loyalty', 'Suscribe');
        if (!popupEventSend) {
            dataLayer.push({
                event: 'newsletter_show',
                method: 'loyalty'
            });
            popupEventSend = true;
        }
    });

    $('.our-products-link .open-modal-newsletter').click(function () {
        openModal();
        trackEvent('Newsletter', 'Menu', 'Clic');
        if (!popupEventSend) {
            dataLayer.push({
                event: 'newsletter_show',
                method: 'menu'
            });
            popupEventSend = true;
        }
    });

    launchNewsletterPopup()

    $(document).on('click', '#close-newsletter-popup', function () {
        trackEvent('Newsletter', 'Popup', 'Close');
        newsletterPopupDismissed();
    });

    $(document).on('click', '.close-modal-newsletter', function (e) {
        $('#loyalty-news').slideUp();
        let date = new Date();
        date.setTime(date.getTime() + (15 * 60 * 1000));
        let expires = "expires=" + date.toUTCString();
        document.cookie = cookieName + "=" + 'true' + ";" + expires + ";path=/";
    });

    setTimeout(function () {
        let element = $('#loyalty-news');
        if (!element.length) return;
        if (!getCookie(cookieName)) {
            element.removeClass('notVisibleNewsletter');
        } else if (!element.hasClass('notVisibleNewsletter')) {
            element.addClass('notVisibleNewsletter');
        }
    }, 500);

    $(document).on('submit', '.newsletter-banner .save-newsletter', function (e) {
        e.preventDefault();
        let form = $(this);
        save(form, function () {
            setTimeout(
                function () {
                    form.trigger("reset");
                    let container = form.closest('.newsletter-home');
                    container.find('.newsletter-text').hide();
                    container.find('.newsletter-form').show();
                },
                30000
            );
        });
        trackEvent('Newsletter', 'Banner', 'Suscribe');
        dataLayer.push({
            event: 'newsletter_subscribe',
            method: 'banner'
        });
    });

    $(document).on('submit', '.newsletter-popup .save-newsletter', function (e) {
        e.preventDefault();
        let form = $(this);
        save(form);
        trackEvent('Newsletter', 'Popup', 'Suscribe');
        dataLayer.push({
            event: 'newsletter_subscribe',
            method: 'popup'
        });
    });

    $(document).on('click', '.newsletter-popup .add-newsletter', function () {
        let email = $('#email-news');
        let terms = $('#privacy-terms');
        let newsletter = $('#mailing');

        if (email.val() == '' || !terms.is(':checked') || !newsletter.is(':checked')) {
            ga4TrackSimpleEvent({
                 event: 'popup_newsletter',
                method: 'error',
            });
        } else {
            ga4TrackSimpleEvent({
                 event: 'popup_newsletter',
                method: 'suscribe',
            });
        }
    });

    let emailNews = true;
    $(document).on('keyup', '.newsletter-popup #email-news', function () {
        setTimeout(function () {
            if (emailNews) {
                ga4TrackSimpleEvent({
                    event: 'popup_newsletter',
                    method: 'type_email',
                });
                emailNews = false;
            }
        }, 2000);
    });

    let emailNewsFooter = true;
    $(document).on('keyup', '.newsletter-footer #email-news-footer', function () {
        setTimeout(function () {
            if (emailNewsFooter) {
                ga4TrackSimpleEvent({
                    event: 'footer_newsletter',
                    method: 'type_email',
                });
                emailNewsFooter = false;
            }
        }, 2000);
    });

    $(document).on('click', '.newsletter-footer .add-newsletter', function () {
        let email = $('#email-news-footer');
        let terms = $('#footer-privacy-terms');
        let newsletter = $('#footer-mailing');

        if (email.val() == '' || !terms.is(':checked') || !newsletter.is(':checked')) {
            ga4TrackSimpleEvent({
                event: 'footer_newsletter',
                method: 'error',
            });
        } else {
            ga4TrackSimpleEvent({
                event: 'footer_newsletter',
                method: 'suscribe',
            });
        }
    });

    $(document).on('submit', '.newsletter-footer .save-newsletter', function (e) {
        e.preventDefault();
        let form = $(this);
        save(form, function(data) {
            let form = $(".newsletter-footer");
            let response = $(".newsletter-footer-response");
            form.hide();
            response.html(data.view);
            response.show();
            trackEvent('Newsletter', 'Popup', 'Suscribe');
            dataLayer.push({
                event: 'newsletter_subscribe',
                method: 'footer'
            });
        });
    });
});

function save(form, callback) {
    let url = form.attr('action');
    let data = form.serialize();
    $.post(
        url,
        data,
        function(data){
            if(data.done) {
                let element = form.closest('.newsletter-home');
                element.find('.newsletter-form').css('display', 'none');
                let voucher = element.find('.newsletter-text');
                voucher.css('display', 'block');
                voucher.html(data.view);
            }
            if (callback) callback(data);
        },
        'json'
    );
    newsletterPopupDismissed();
}

let newsletterTimeout = null;
export function launchNewsletterPopup() {
    if (canShowNewsletterPopup()) {
        clearTimeout(newsletterTimeout);
        newsletterTimeout = setTimeout(() => {
            if (canShowNewsletterPopup()) {
                if (/*document.getElementById('loyalty-newsletter') &&*/ !getModal('popup-newsletter')) {
                    openModal(true);
                    if (!popupEventSend) {
                        ga4TrackSimpleEvent({
                            event: 'popup_newsletter',
                            method: 'view',
                        });
                        popupEventSend = true;
                    }
                }
            }
        }, 30000);
    }
}

function openModal(autoModal) {
    let newsletter = $('#newsletter-modal');
    let payload = {};
    if (autoModal) {
        payload = {autoModal: true};
    }
    $.post(
        '/ajax/usuarios/newletter-modal',
        payload,
        function(data){
            newsletter.html(data.view);
            newsletter.modal('show');
            newsletter.on('hidden.bs.modal', function (e) {
                newsletterPopupDismissed();
            })
        },
        'json'
    );
}

function canShowNewsletterPopup() {
    let canShow = true;
    let skipPopup = document.getElementsByClassName('skip-newsletter-popup').length;
    if (!getCookie('_tv_cookie_choice')) return false;
    if (!getCookie(cookieName) && !skipPopup) {
        if (typeof (Storage) !== 'undefined') {
            if (localStorage.getItem('tvn_shnewspop')) {
                let d1 = new Date();
                canShow = ((d1.getTime() - localStorage.getItem('tvn_shnewspop'))/1000/86400) > 30;
            } else {
                canShow = true;
            }
        } else {
            canShow = !(getCookie('tvn_shnewspop') === '1');
        }
    } else {
        canShow = false;
    }

    return canShow;
}

function newsletterPopupDismissed() {
    if (typeof (Storage) !== 'undefined') {
        let d = new Date();
        localStorage.setItem('tvn_shnewspop', d.getTime());
    } else {
        setCookie('tvn_shnewspop', '1', 30);
    }
}