export function createModalFromHtml(html, options = {})
{
    if (typeof html !== 'string') {
        return null;
    }

    let modal = document.createElement('div');
    modal.innerHTML = html;
    let modalDiv = modal.firstChild;
    document.body.appendChild(modalDiv);

    return new bootstrap.Modal(document.getElementById(modalDiv.id), options);
}

export function openModalFromHtml(html, options = {}, removeOnHide = true)
{
    let modal = createModalFromHtml(html, options);
    modal.show();

    if (removeOnHide) {
        modal._element.addEventListener('hidden.bs.modal', function () {
            this.remove();
        });
    }
}

export function getModal(id)
{
    let modal = document.getElementById(id);

    return modal ? bootstrap.Modal.getOrCreateInstance(modal) : null;
}

export function hideModal(id)
{
    let modal = getModal(id);

    if (modal) {
        modal.hide();
    }
}

export function showModal(id)
{
    let modal = getModal(id);

    if (modal) {
        modal.show();
    }
}

export function disposeModal(id)
{
    let modal = getModal(id);

    if (modal) {
        modal.dispose();
        document.getElementById(id).remove();
    }
}

export function openLoadingModal()
{
    if (document.getElementById('loadingModal')) return;

    let html = '<div class="modal fade" id="loadingModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true">' +
        '  <div class="modal-dialog modal-dialog-centered" role="document">' +
        '    <div class="modal-content bg-transparent border-0">' +
        '      <div class="modal-body text-center">' +
        '        <div class="spinner-border text-white text-size-30" style="width: 4rem; height: 4rem;" role="status">\n' +
        '          <span class="visually-hidden">Loading...</span>\n' +
        '        </div>' +
        '      </div>' +
        '    </div>' +
        '  </div>' +
        '</div>';

    openModalFromHtml(html);
}

export function closeLoadingModal()
{
    setTimeout(function(){ hideModal('loadingModal') }, 200);
}

export function openSimpleErrorModal(error)
{
    let html = '<div class="modal fade" id="simpleErrorModal" tabindex="-1" role="dialog" aria-hidden="true">' +
        '  <div class="modal-dialog modal-dialog-centered" role="document">' +
        '    <div class="modal-content border-0">' +
        '      <div class="modal-body text-center bg-error text-error">' +
        error +
        '      </div>' +
        '    </div>' +
        '  </div>' +
        '</div>';

    openModalFromHtml(html);
}