export let ajax = {
    post: post,
    get: get,
    uploadImage: uploadImage,
};

function post(url, data, success, error) {
    send('POST', url, data, success, error);
}

function get(url, data, success, error) {
    send('GET', url, data, success, error);
}

function uploadImage(file, success, error) {
    if (file) {
        let url = '/ajax/upload/upload-image';
        let data = {'0': file};
        post(url, data, success, error);
    }
}

function send(method, url, data, success, error) {
    let xhr = new XMLHttpRequest();
    xhr.onload = function () {
        let response = JSON.parse(xhr.response);
        if (xhr.status >= 200 && xhr.status < 300) {
            if (response.done === false) {
                typeof error === 'function' && error(response);
            } else {
                typeof success === 'function' && success(response);
            }
        } else {
            typeof error === 'function' && error(response);
        }
    };
    xhr.open(method, url);
    xhr.send(buildFormData(data));
}

/*function buildFormData(data) {
    let formData = null;
    if (typeof data === 'object' && data !== null) {
        formData = new FormData();
        for (let key in data) {
            if (data.hasOwnProperty(key)) {
                formData.append(key, data[key]);
            }
        }
    }

    return formData;
}*/
function buildFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;
    for (let property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                buildFormData(obj[property], fd, formKey);
            } else {
                fd.append(formKey, obj[property]);
            }
        }
    }
    return fd;
}