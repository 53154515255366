
const maxFileSizeDefault = 15;
const maxMultipleFileSizeDefault = 250;

export function post(url, data, success, error) {
    return send('POST', url, data, success, error);
}

export function get(url, data, success, error) {
    return send('GET', url, data, success, error);
}

export function upload(file, data, success, error) {
    let fileSize = Math.round((file.size / 1024 / 1024) * 100) / 100;

    if (fileSize > maxFileSizeDefault) {
        post(
            '/ajax/website/translation/translate',
            {type: 'upload-error-max-size'},
            function(data) {
                typeof error === 'function' && error(data.translation);
            }
        )
    } else {
        post(
            '/ajax/website/upload/image',
            data,
            success,
            function(data) {
                typeof error === 'function' && error(data.error);
            }
        );
    }
}

export function uploadMultiple(files, data, success, error) {
    let filesSize = 0;
    for (let i = 0, file; file = files[i]; i++) {
        filesSize += Math.round((file.size / 1024 / 1024) * 100) / 100;
    }

    if (filesSize > maxMultipleFileSizeDefault) {
        post(
            '/ajax/website/translation/translate',
            {type: 'upload-multiple-error-max-size'},
            function(data) {
                typeof error === 'function' && error(data.translation);
            }
        )
    } else {
        post(
            '/ajax/website/upload/image-multiple',
            data,
            success,
            function(data) {
                typeof error === 'function' && error(data.error);
            }
        );
    }
}

export function uploadImageBase64(data, success, error)
{
    post(
        '/ajax/website/upload/image-base-64',
        data,
        success,
        function(data) {
            typeof error === 'function' && error(data.error);
        }
    );
}

function send(method, url, data, success, error) {
    let xhr = new XMLHttpRequest();
    let formData = null;
    let queryData = '';
    xhr.onload = function () {
        let response = JSON.parse(xhr.response);
        if (xhr.status >= 200 && xhr.status < 300) {
            if (response.done === false) {
                typeof error === 'function' && error(response);
            } else {
                typeof success === 'function' && success(response);
            }
        } else {
            typeof error === 'function' && error(response);
        }
    };
    if (method == 'GET') {
        queryData = buildQueryData(data);
    } else if (method == 'POST') {
        formData = buildFormData(data);
    }
    xhr.open(method, url+queryData);
    xhr.onreadystatechange = function() {
        if (xhr.readyState == 4 && !(xhr.status >= 200 && xhr.status < 300)) {
            xhr.error = xhr.statusText;
            typeof error === 'function' && error(xhr);
        }
    }
    xhr.send(formData);

    return xhr;
}

function buildQueryData(data) {
    let name;
    let value;
    let query = '';
    for (let property in data) {
        if (data.hasOwnProperty(property) && data[property]) {
            name = property;
            if (data[property] instanceof Date) {
                value = data[property].toISOString();
            } else {
                value = data[property];
            }

            query += (query ? '&' : '?') + name + '=' + value;
        }
    }

    return query;
}

function buildFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey;
    for (let property in obj) {
        if (obj.hasOwnProperty(property)) {
            if (namespace) {
                formKey = namespace + '[' + property + ']';
            } else {
                formKey = property;
            }
            if (obj[property] instanceof Date) {
                fd.append(formKey, obj[property].toISOString());
            } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                buildFormData(obj[property], fd, formKey);
            } else {
                fd.append(formKey, obj[property]);
            }
        }
    }
    return fd;
}