export function popupInfoDialog(text, showLogo, scrollable) {
    showLogo = showLogo || false;
    scrollable = scrollable || false;
    if ($('#info-dialog').length) {
        $('#info-dialog').modal('hide');
        $('#info-dialog').remove();
    }
    if ($('.modal-backdrop').length) {
        $('.modal-backdrop').remove();
    }

    let logo = "<button type='button' class='close' data-dismiss='modal' aria-hidden='true' style='position: absolute;right: 5px;z-index: 1;font-size: 25px;'>×</button>";
    if (showLogo) {
        scrollable = true;
        logo = "<div class='modal-header'>" +
            "<img src='/build/images/web/logos/sticker-logo.png' style='width: 40px'>" +
            "</div>";
    }
    let modalScrollable = '';
    if (scrollable) {
        modalScrollable = ' modal-scrollable';
    }
    let dialog =
        "<div id='info-dialog' class='modal"+modalScrollable+" fade' tabindex='-1'>" +
        "<div class='modal-dialog modal-dialog-scrollable' role='document'>" +
        "<div class='modal-content'>" +
        logo +
        "<div class='modal-body'>" +
        text +
        "</div></div></div></div>";
    $(dialog).modal();
}